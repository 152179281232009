import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/Project.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Pentomino Cover Game`}</strong>{` is a small puzzle that I developed back in 2013,
when I was learning Python. I initially wrote it in Python 2, and later decided
to port it to Python 3.`}</p>
    <p>{`I made this game after I saw a wooden version of it, and I was fascinated by
just how `}<em parentName="p">{`incredibly hard`}</em>{` it is to find a solution to this puzzle. (Hint: you
have a better chance at finding it if you can code! 😉)`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "106.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAB2HAAAdhwGP5fFlAAAFHklEQVQ4y0WUW3NTVRTH8w38Co5v6ptv6ozDjOOo6GhR0EpBaEstFIpYVETkqkhFqiA4423whtNKm+Z+ck+LgjXJ2efsc9KkSdNqW7QBsQrNrU2rPycnzPiwZ6+99lp7r7X+/7Vs+elZ5q8t8MeNv1mq/cvKKtRWGvv/619Wbutq9fM/UKkuW3Z1n7quWF6iVCpjm5v9hflZg8KMRmFGpTArufZblsKM4FpdnpMUrmYpXM39f55RGzaWn8r1Ocn8jM6N679hM+Nu1P41iKEm1MF1aO5txIN9xPsfIzGwFtG/BtXZiureheh/iHj/WtSBRxHhk4zZX0IMNiHs60leeIBscgDbeGIY3d6EjH2I9HVj+Hsaa+R9/lq4wbR6HunejOZqY/Lye9y8VSR95ROk8jLSvxfp24mM9SHsG5gS39QftKMNN2P4d2M6nkJX9qK5WjFGPmCxVGPGHEK6XkQ4tzKd+JjSEqR/+gLNuQVd6cFwbiClvIRwbGZK/boRYWJoI7q3Cz2wn/FQD7qjGc25iaS9haT/MLq3k6Srk6T/IJq7AzHcjO5swfRtRwsewlS6iNs3k1e/xZbWR0hGzqB7OlFdnWSVFxCBI2j+15D+V9FDx5Cxc2ixj9BDRzCC+6wsjGgvcngdmqeLlK+dRPhD8qnI7Ro6nkOGjyACh8mGOhH+NzCHHiYT2MGE8jSpSAdm9G0mlI2kA9tJOx8jFdqHcGxBhg5ad8KxsZFyOulEG2riVmmVQmGejLcZzbcHqexk4RbcXDxMpXoHN29d5a+byywUIXPpOCn/TpKD65n/VaWyCpqjhSn1m0aE2sVH+D0fYzYTQMZOIQP7MJU2imWoVLqp1WwUF6cpFUsUq5C7dAjT30UieJJfTTvXZxNog0+ST36FbVwEkc5mxPBzqEPPIpXdiNBxjPABFktQLu9lednG4uLvFBdXKVYge+UcMnyIhPsVNPt6iyX68DNM6u46KKMI78vo3h0WgqnwfrSRcxijpyhXoFrdxcqKjWr1Lpaqd1JbvZtsohsZ+xQt8Ca6p8PiouraTt60QHEgL64hO3qUzMghjMgx9MDrmL42ivUIS6dZXr6Hcvk+qpV7qdQeYPKHJzGVvST8h8lED5C73Iv+/ZoGbSaEG3WwifIS3PhzAenrQlNexQz0UFqGchWWalAqN+TSCuR+7MP0d1utV5hLswIIxybydVCywom4+DiT2jDjY9+S9W9C83ajD64lF/+MybHT5MUFJsUA+bE+cvHPSbk3kPJ3We2WufIJU6aCGHqGqeR5bBMyypjnIMK1jaSzg4lgB2NKL3H7VpKONoSjhYR3P3HlqMW1ui45vAU1fMpqN+HegfS0Wm/kdA+2STOKHjyAVLoRyutklRbSvk1kQzswfe3I0Y9Ij57AiLyDMXqWdLCbTGAbE4F20q51aP79pAK70AJvMW24seU0J+rg00hPB4a3DSP2njVJjMhRZtIBslfOIp0vWDXKjBxjLncJM/Yuum934xNlJ6a3FXVoA1Pql/UauhH2ZszRDzDdLQhXK7rjeeTIGZaBqxNBdNcWVGcbv4jzrALjYxcs/unudgzXRsxYL6pjawOUjHAjBx5E97RbY0v696BGTqM5NzMe3E3K9awVYd055XiC8eAepPtF9GgfSU8PumurNY30/vtv0yb1M1q0r5Fq5F3M0TPIn75HCx1Dj5xARk8gf/wSefk7jOiJhi78NuZYP1rsLDJyHHPkJFq4l8nxy/wH5EktR8T+tYkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pentomino",
            "title": "Screenshot of the game with 3 pentominoes still to be placed",
            "src": "/static/698f8cee8da3f73206bcb13d32c48c0b/0b533/pentomino.png",
            "srcSet": ["/static/698f8cee8da3f73206bcb13d32c48c0b/fac75/pentomino.png 125w", "/static/698f8cee8da3f73206bcb13d32c48c0b/63868/pentomino.png 250w", "/static/698f8cee8da3f73206bcb13d32c48c0b/0b533/pentomino.png 500w", "/static/698f8cee8da3f73206bcb13d32c48c0b/1d69c/pentomino.png 750w", "/static/698f8cee8da3f73206bcb13d32c48c0b/136a2/pentomino.png 884w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Screenshot of the game with 3 pentominoes still to be placed`}</figcaption>{`
  `}</figure></p>
    <h2>{`Running the game`}</h2>
    <p>{`You can download the game from its `}<a parentName="p" {...{
        "href": "https://github.com/wil93/pentomino-cover-game"
      }}>{`Github repository`}</a>{`.
(You can also simply download the
`}<a parentName="p" {...{
        "href": "https://raw.githubusercontent.com/wil93/pentomino-cover-game/master/game.py"
      }}>{`game.py`}</a>{`
file.) Make sure that the Tk GUI toolkit is available on your system. On Ubuntu,
run: `}<inlineCode parentName="p">{`sudo apt install python3-tk`}</inlineCode></p>
    <p>{`Finally, launch the game like so: `}<inlineCode parentName="p">{`python3 game.py`}</inlineCode></p>
    <h2>{`Bonus`}</h2>
    <p>{`On top of this “virtual” version of the game, I also made a `}<strong parentName="p">{`physical version`}</strong>{`
of it with the help of my uncle. It's made of wood, like most of the things my
uncle helped me build. I guess one could make this more easily with a 3D
printer, but where's the fun in that? 😆`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "500px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "86.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAARABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAEEBQID/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAGb+V1hsINn2CPYN//EABsQAQACAgMAAAAAAAAAAAAAAAIAEQESAzEz/9oACAEBAAEFAtdMkhFillwciu4vUdz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAVEQEBAAAAAAAAAAAAAAAAAAAQAf/aAAgBAgEBPwEp/8QAGRAAAgMBAAAAAAAAAAAAAAAAACEBESBB/9oACAEBAAY/AmckoYs//8QAGhABAAIDAQAAAAAAAAAAAAAAAQARIDFBIf/aAAgBAQABPyGEAKohONTvvC+lEUqvcA//2gAMAwEAAgADAAAAEFjPwf/EABcRAAMBAAAAAAAAAAAAAAAAAAEQETH/2gAIAQMBAT8QlQxf/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBEDH/2gAIAQIBAT8QaEaiv//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVGxcf/aAAgBAQABPxANiVcAaruI1i3XPsTXDJm5YqWk3x8gVunVblTSq1qaJ4Rn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "pentomino wood",
            "title": "Work in Progress",
            "src": "/static/5738745ceaf690671f8c27300e2f7851/41099/pentomino-wood.jpg",
            "srcSet": ["/static/5738745ceaf690671f8c27300e2f7851/aa2ed/pentomino-wood.jpg 125w", "/static/5738745ceaf690671f8c27300e2f7851/0479a/pentomino-wood.jpg 250w", "/static/5738745ceaf690671f8c27300e2f7851/41099/pentomino-wood.jpg 500w", "/static/5738745ceaf690671f8c27300e2f7851/acb04/pentomino-wood.jpg 750w", "/static/5738745ceaf690671f8c27300e2f7851/9100a/pentomino-wood.jpg 959w"],
            "sizes": "(max-width: 500px) 100vw, 500px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Work in Progress`}</figcaption>{`
  `}</figure></p>
    <p>{`Since the puzzle's solution is so hard, it doesn't really make for a very fun
game to play… But it's a nice way to keep guests busy 😈`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      