import { Link } from "gatsby";
import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SEO from "../components/SEO";

const ProjectPage = ({ pageContext, children }) => {
  const { title, description } = pageContext.frontmatter;

  return (
    <main className="site">
      <SEO
        title={`${title} - William Di Luigi`}
        description={description}
      />

      <Navbar />

      <div className="site-content slim">
        <Link
          to="/projects"
          className="text-base md:text-sm text-green-500 font-bold no-underline uppercase hover:underline"
        >
          &lt; Back to Projects
        </Link>

        <h1 className="font-bold font-sans break-normal pb-2 text-3xl md:text-4xl">{title}</h1>
        <h2 className="text-sm md:text-base font-light text-gray-600">{description}</h2>
        
        <div className="prose prose-lg max-w-none mt-6">
          {children}
        </div>
      </div>

      <Footer />
    </main>
  );
}

export default ProjectPage